<template>
  <v-container fluid>
    <v-tabs v-model="tab">
      <v-tab v-for="group in filterGroups" :key="group.value">
        {{ group.text }}
      </v-tab>
    </v-tabs>
    <v-card class="pa-5 mt-3">
      <v-data-table
        :headers="headers"
        :items="services"
        :search="search"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              single-line
              :label="$t('search')"
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" persistent max-width="500px">
              <template v-slot:activator="{ on, attrs }" v-if="isInRole('50')">
                <v-btn
                  v-if="isInRole('50')"
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t("add") }}

                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </template>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                  <v-toolbar color="primary darken-1" dark>
                    <v-card-title>
                      <span class="">{{
                        (editedIndex === -1 ? $t("add") : $t("edit")) +
                          " " +
                          $t("services.service")
                      }}</span>
                    </v-card-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.serviceName"
                            :rules="rules"
                            :label="$t('services.serviceName')"
                          ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12">
                          <v-select
                            v-model="editedItem.serviceGroup"
                            :items="groups"
                            :label="$t('services.serviceGroup')"
                            required
                            item-text="text"
                            item-value="value"
                          ></v-select>
                        </v-col> -->

                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.serviceNote"
                            :label="$t('notes')"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                      color="green darken-1"
                      class="white--text"
                      @click="save"
                      :disabled="!valid"
                      :min-width="100"
                    >
                      <v-icon>mdi-content-save-outline</v-icon>
                      {{ $t("save") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="close">
                      {{ $t("cancel") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>

            <confirm-dialog
              :openDialog="dialogDelete"
              :onClicked="deleteItemConfirm"
              :onClose="closeDelete"
            ></confirm-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="text-end">
            <v-tooltip top v-if="isInRole('51')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="editItem(item)" v-bind="attrs" v-on="on">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span> {{ $t("edit") }} </span>
            </v-tooltip>
            <v-tooltip top v-if="isInRole('52')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="red darken-2"
                  @click="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span> {{ $t("delete") }}</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.serviceGroup`]="{ item }">
          <v-chip class="ma-2"> {{ getText(item.serviceGroup) }} </v-chip>
        </template>
      </v-data-table>
      <v-divider />
      <v-row class="mt-2">
        <v-col cols="2"></v-col>

        <v-col align-self="end">
          <v-pagination
            class="mb-2 "
            v-model="page"
            :length="pageLength ? pageLength : 0"
          ></v-pagination>
        </v-col>
        <v-col align-self="center" cols="2">
          <v-select
            dense
            class="pa-1"
            solo
            :label="$t('itemPerPage')"
            :items="itemPerPageSelect"
            item-text="text"
            item-value="value"
            v-model="itemsPerPage"
            hide-details=""
          >
          </v-select>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
export default {
  components: { ConfirmDialog },
  data() {
    return {
      page: 1,
      itemsPerPage: 5,
      itemPerPageSelect: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "15", value: 15 },
        { text: this.$t("all"), value: -1 },
      ],
      tab: 0,

      valid: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      search: "",
      headers: [
        { text: this.$t("services.serviceName"), value: "serviceName" },
        { text: this.$t("services.serviceGroup"), value: "serviceGroup" },
        { text: this.$t("notes"), value: "serviceNote" },
        { text: "", value: "actions" },
      ],
      services: [],
      editedItem: {
        serviceName: "",
        serviceGroup: 0,
        serviceNote: "",
      },
      defaultItem: {
        serviceName: "",
        serviceGroup: 0,
        serviceNote: "",
      },
      groups: [
        {
          text: this.$t("services.serviceGroupType.general"),
          value: 0,
          role: "65",
        },
        {
          text: this.$t("services.serviceGroupType.dental"),
          value: 1,
          role: "66",
        },
        {
          text: this.$t("services.serviceGroupType.operation"),
          value: 2,
          role: "67",
        },
      ],
      rules: [(value) => !!value || "Required."],
    };
  },
  created() {
    this.refreshTable();
  },

  computed: {
    filterGroups() {
      return this.groups.filter((f) => this.isInRole(f.role));
    },
    pageLength() {
      if (this.itemsPerPage == -1) {
        return 1;
      } else {
        var div = this.services.length / this.itemsPerPage;
        return Number.isInteger(div) ? div : (div | 0) + 1;
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    tab(val) {
      this.refreshTable();
      this.editedItem.serviceGroup = this.filterGroups[val].value;
    },
    pageLength(val) {
      if (this.page > val) this.page = val;
    },
  },

  methods: {
    getText(i) {
      var result = this.groups.filter((obj) => {
        return obj.value == i;
      });
      return result[0].text;
    },
    editItem(item) {
      console.log(item);
      this.editedIndex = this.services.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.services.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.services[this.editedIndex];

      axios
        .delete("Service/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    refreshTable() {
      axios
        .get("Service/Get?group=" + this.filterGroups[this.tab].value)
        .then((response) => {
          this.services = response.data.data;
        });
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.services[this.editedIndex];
          updatedItem.serviceName = this.editedItem.serviceName;
          // updatedItem.serviceGroup = this.editedItem.serviceGroup;
          updatedItem.serviceNote = this.editedItem.serviceNote;

          axios
            .post("Service/Update", updatedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });
          Object.assign(this.services[this.editedIndex], this.editedItem);
        } else {
          this.editedItem.serviceGroup = this.filterGroups[this.tab].value;
          axios
            .post("Service/Add", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });
        }
      }
    },
  },
};
</script>

<style></style>
